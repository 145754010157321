import { Tooltip, Popover, Tab, Modal } from './_bootstrap';
import Mmenu from 'mmenu-js';
import GLightbox from 'glightbox';

window.addEventListener('load', () => {
    initializeLightbox();
    initializeBackButtonReload();
    stickyTabNavigation();
});

document.addEventListener('DOMContentLoaded', () => {
    initializePageFadeEffect();
    initializeMmenu();
    initializeFadeIn();
    initializeBootstrapJs();
    copySidebarOnModal();
    initializeDropdownOverlay();
    initializeTabQueryNavigation();
    centerActiveLink();
    customConfirmInit();
});

// Gibt die ID des ersten Tabs zurück, egal ob über href oder data-bs-target
function getFirstTabId() {
    const firstTabLink = document.querySelector('[data-bs-toggle="tab"]');
    if (firstTabLink) {
        let tabTarget = firstTabLink.getAttribute('href') || firstTabLink.getAttribute('data-bs-target');
        if (tabTarget && tabTarget.startsWith('#')) {
            return tabTarget.substring(1);
        }
    }
    return null;
}

// Initialisiert die Tab-Navigation und aktualisiert den Query-Parameter
function initializeTabQueryNavigation() {
    const tabTriggerElements = document.querySelectorAll('[data-bs-toggle="tab"]');

    // Hilfsfunktion: Ermittelt den Tab-Target (ohne das führende #)
    function getTabTarget(element) {
        let target = element.getAttribute('href') || element.getAttribute('data-bs-target');
        if (target && target.startsWith('#')) {
            return target.substring(1);
        }
        return null;
    }

    // 1. Beim Wechsel eines Tabs den Query-Parameter aktualisieren
    tabTriggerElements.forEach((triggerEl) => {
        triggerEl.addEventListener('shown.bs.tab', function () {
            const tabId = getTabTarget(triggerEl);
            if (tabId) {
                const url = new URL(window.location);
                url.searchParams.set('tab', tabId); // Query-Parameter setzen
                history.replaceState(null, '', url);
            }
        });
    });

    // 2. Den aktiven Tab ermitteln, entweder per URL-Parameter oder anhand des ersten Tabs
    const urlParams = new URLSearchParams(window.location.search);
    const activeTab = urlParams.get('tab') || getFirstTabId();

    // Selektiert den entsprechenden Trigger, egal ob er href oder data-bs-target verwendet
    const tabTriggerEl = document.querySelector(`[href="#${activeTab}"], [data-bs-target="#${activeTab}"]`);
    if (tabTriggerEl) {
        // Hier wird die Bootstrap-Tab-Instanz initialisiert (achte darauf, dass bootstrap.Tab verfügbar ist)
        const tab = new Tab(tabTriggerEl);
        tab.show();
    }
}

function initializePageFadeEffect() {
    // Einblenden der Seite beim Laden
    window.addEventListener('pageshow', () => {
        const sitePreloader = document.querySelector('.site-preloader');
        const pageContent = document.querySelector('.page');
        if (pageContent) {
            pageContent.classList.remove('fade-out');
            pageContent.classList.add('fade-in');
            centerActiveLink();
        }
        if (sitePreloader) {
            sitePreloader.classList.remove('fade-in');
            sitePreloader.classList.add('fade-out');
        }
    });

    function handlePageUnload() {
        const pageContent = document.querySelector('.page');
        if (pageContent) {
            pageContent.classList.remove('fade-in');
            pageContent.classList.add('fade-out');
        }
        const sitePreloader = document.querySelector('.site-preloader');
        if (sitePreloader) {
            sitePreloader.classList.remove('fade-out');
            sitePreloader.classList.add('fade-in');
        }
    }

    window.addEventListener('pagehide', handlePageUnload);
    window.addEventListener('beforeunload', handlePageUnload); // weiterhin für Desktop
}

function centerActiveLink() {
    const horizontalScrollNav = document.querySelectorAll('.scrolling-wrapper');
    horizontalScrollNav.forEach(wrapper => {
        wrapper.addEventListener('click', (event) => {
            scrollToActive(wrapper);
        });

        scrollToActive(wrapper);

        window.addEventListener('resize', () => {
            scrollToActive(wrapper);
        });

    });
}

function scrollToActive(wrapper) {
    const activeLink = wrapper.querySelector('.is-active') || wrapper.querySelector('.active');
    if (activeLink) {
        activeLink.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
    }
}

function initializeFadeIn() {
    const elementsFade = document.querySelectorAll('.transition-fade');
    elementsFade.forEach(element => {
        element.classList.add('fade-in');
    });
}

function initializeBootstrapJs() {
    document.querySelectorAll('[data-bs-toggle="tooltip"], [data-bs-toggle="popover"], [data-bs-toggle="modal"]').forEach(element => {
        if (element.getAttribute('data-bs-toggle') === 'tooltip' || element.getAttribute('data-bs-tooltip') === 'tooltip') {
            new Tooltip(element); // Direkt die importierte Klasse verwenden
        } else if (element.getAttribute('data-bs-toggle') === 'popover') {
            new Popover(element); // Direkt die importierte Klasse verwenden
        }
    });
}

function initializeMmenu() {
    const mmenuElement = document.querySelector('#mmenu');
    if (mmenuElement) {
        new Mmenu('#mmenu', {
            slidingSubmenus: true,
            theme: 'light',
            setSelected: {
                hover: true,
                parent: true,
            },
            navbar: {
                add: true,
                title: '<i class="fa-solid fa-house"></i>',
                titleLink: 'none',
            },
            scrollBugFix: {
                fix: true
            },
            offCanvas: {
                position: 'left-front'
            },
            language: 'de',
            navbars: [
                {
                    position: 'top',
                    content: [
                        'prev',
                        '<a class="mm-menu-logo" style="padding-top: 5px;" href="/"><img src="/themes/custom/pax_radix/logo.svg" width="82" alt="MyPaX" /></a>',
                        'breadcrumbs',
                        'close'
                    ]
                }
            ]
        }, {
            offCanvas: {
                page: {
                    selector: '.dialog-off-canvas-main-canvas'
                }
            }
        });
    }
}

function copySidebarOnModal() {
    const sidebarContent = document.getElementById('sidebarContent');
    const modalSidebarContent = document.getElementById('modalSidebarContent');
    const sidebarRightModal = document.getElementById('sidebarRightModal');

    if (sidebarContent && modalSidebarContent && sidebarRightModal) {
        sidebarRightModal.addEventListener('show.bs.modal', () => {
            if (modalSidebarContent.innerHTML === '') {
                modalSidebarContent.innerHTML = sidebarContent.innerHTML;
            }
        });
    }
}

// BUGFIX Bootstrap modal appearing under backdrop
document.addEventListener('show.bs.modal', (event) => {
    const modal = event.target;
    document.body.appendChild(modal);
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
        modal.style.zIndex = parseInt(window.getComputedStyle(backdrop).zIndex, 10) + 1;
    }
    modal.focus();
});

function initializeLightbox() {
    // Initialisiere die erste Lightbox mit spezifischen Einstellungen
    const lightbox = GLightbox({
        loop: false,
        touchNavigation: true,
        moreText: 'Mehr anzeigen',
        moreLength: 128,
        openEffect: 'zoom',
        closeEffect: 'zoom',
        descPosition: 'right',
    });
}

function initializeDropdownOverlay() {
    let el_overlay = null;
    let openDropdownCount = 0;

    document.body.addEventListener('shown.bs.dropdown', (event) => {
        openDropdownCount++;
        if (openDropdownCount === 1) {
            // Nur wenn dies der erste geöffnete Dropdown ist, Overlay erzeugen
            el_overlay = document.createElement('span');
            el_overlay.className = 'screen-darken';
            const pageWrapper = document.querySelector('#page-wrapper');
            if (pageWrapper) {
                pageWrapper.appendChild(el_overlay);
            }
        }
    });

    document.body.addEventListener('hide.bs.dropdown', () => {
        // Nach einer kleinen Verzögerung prüfen, ob noch ein Dropdown offen ist
        // Die Verzögerung ist wichtig, um sicherzustellen, dass der aktuelle Dropdown
        // wirklich geschlossen wurde und ggf. ein anderer geöffnet ist.
        setTimeout(() => {
            // Aktualisiere die Anzahl offener Dropdowns, indem geprüft wird,
            // wie viele Dropdown-Menüs noch angezeigt werden
            openDropdownCount = document.querySelectorAll('.dropdown-menu.show').length;
            
            if (openDropdownCount === 0 && el_overlay) {
                // Wenn keine Dropdowns mehr offen sind, Overlay entfernen
                el_overlay.parentNode.removeChild(el_overlay);
                el_overlay = null;
            }
        }, 50);
    });
}

document.body.addEventListener('mousedown', (e) => {
    const target = e.target.closest('.btn, .ripple-init, .ripple-wrapper');

    // Ripple nicht auslösen, wenn der Button im Modal ist
    if (target && target.tagName !== 'INPUT' && !target.closest('#customConfirmModal')) {
        createRipple(e, target);
    }
});

function createRipple(event, target) {
    const ripple = document.createElement('div');
    ripple.classList.add('ripple');
    const rect = target.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${event.clientX - rect.left - size / 2}px`;
    ripple.style.top = `${event.clientY - rect.top - size / 2}px`;
    target.appendChild(ripple);
    ripple.addEventListener('animationend', () => ripple.remove());
}

function initializeBackButtonReload() {
    window.addEventListener('pageshow', function(event) {
        if (event.persisted) {
            reloadIfNotReloaded();
        } else {
            const entries = performance.getEntriesByType('navigation');
            if (entries.length > 0 && entries[0].type === 'back_forward') {
                reloadIfNotReloaded();
            }
        }
    });

    function reloadIfNotReloaded() {
        if (!window.location.hash.includes('reloaded')) {
            window.location.reload();
            window.location.hash = 'reloaded';
        }
    }
}

function stickyTabNavigation() {
    const stickyElement = document.querySelector(".sticky-tab-nav");
    if (stickyElement) {
    const observerTarget = document.createElement("div");
    stickyElement.parentElement.insertBefore(observerTarget, stickyElement);

    const updateObserverTargetPosition = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const offsetTop = stickyElement.offsetTop;
        observerTarget.style.position = "absolute";
        observerTarget.style.height = "1px";
        observerTarget.style.width = "1px";
        observerTarget.style.top = `${offsetTop + scrollTop}px`;
    };

    // Sicherstellen, dass die Position korrekt berechnet wird
    setTimeout(() => {
        updateObserverTargetPosition();
    }, 1000);

    const totalOffset = 65 + stickyElement.offsetHeight;

    let initialLoad = true;

    const observer = new IntersectionObserver(
        ([entry]) => {
        if (initialLoad) {
            initialLoad = false;
            return; // Ignoriere den ersten Check
        }

        if (!entry.isIntersecting) {
            stickyElement.classList.add("is-sticky");
            //console.log("Sticky hinzugefügt");
        } else {
            stickyElement.classList.remove("is-sticky");
            //console.log("Sticky entfernt");
        }
        },
        {
            root: null,
            threshold: 0,
            rootMargin: `-${totalOffset}px 0px 0px 0px`,
        }
    );

    observer.observe(observerTarget);

    window.addEventListener("resize", updateObserverTargetPosition);
    window.addEventListener("scroll", () => {
        const rect = stickyElement.getBoundingClientRect();
        if (rect.top <= 65 && !stickyElement.classList.contains("is-sticky")) {
            stickyElement.classList.add("is-sticky");
        } else if (rect.top > 65 && stickyElement.classList.contains("is-sticky")) {
            stickyElement.classList.remove("is-sticky");
        }
    });
    }
}

function hasCamera() {
	// Überprüfe, ob die MediaDevices API unterstützt wird
	if (navigator.mediaDevices && typeof navigator.mediaDevices.enumerateDevices === 'function') {
		return navigator.mediaDevices.enumerateDevices()
		.then(devices => devices.some(device => device.kind === 'videoinput'));
	}
	return Promise.resolve(false);
}
  
// Test-Code: Prüfe, ob eine Kamera vorhanden ist und zeige das Ergebnis an.
hasCamera().then(cameraPresent => {
	const cameraElements = document.querySelectorAll('.camera-only.d-none');
	if (cameraPresent) {
		cameraElements.forEach(el => el.classList.remove('d-none'));
		
	}
});

function customConfirm(message, options = {}) {
    return new Promise((resolve) => {
        const modalElement = document.getElementById("customConfirmModal");
        const okButton = document.getElementById("customConfirmOk");
        const cancelButton = document.getElementById("customConfirmCancel");

        // Standardwerte setzen
        const defaultOptions = {
            title: "Bestätigung",
            okText: "Ok",
            cancelText: "Abbrechen",
            okClass: "btn-success", // Standardklasse für den OK-Button
            cancelClass: "btn-danger", // Standardklasse für den Abbrechen-Button
        };

        // Falls Optionen übergeben wurden, überschreiben sie die Standardwerte
        const finalOptions = { ...defaultOptions, ...options };

        // 🎯 Vorherige Event-Listener entfernen, um doppelte Klicks zu vermeiden
        okButton.replaceWith(okButton.cloneNode(true));
        cancelButton.replaceWith(cancelButton.cloneNode(true));

        // Neue Buttons nach dem Klonen holen
        const newOkButton = document.getElementById("customConfirmOk");
        const newCancelButton = document.getElementById("customConfirmCancel");

        // 🎯 1️⃣ Texte setzen
        document.getElementById("customConfirmLabel").textContent = finalOptions.title;
        document.getElementById("customConfirmMessage").textContent = message;

        newOkButton.querySelector("span").innerHTML = finalOptions.okText;
        newCancelButton.querySelector("span").innerHTML = finalOptions.cancelText;

        // 🎯 2️⃣ Klassen setzen
        newOkButton.className = `btn ${finalOptions.okClass} rounded-1`;
        newCancelButton.className = `btn ${finalOptions.cancelClass} rounded-1`;

        // Bootstrap Modal initialisieren
        const modal = new Modal(modalElement, { backdrop: "static", keyboard: false });

        function onModalShown() {
            // Sicherstellen, dass Buttons aktiv sind & Fokus setzen
            setTimeout(() => newCancelButton.focus(), 50);

            // Event-Listener setzen
            newOkButton.addEventListener("click", () => {
                modal.hide();
                resolve(true);
            });

            newCancelButton.addEventListener("click", () => {
                modal.hide();
                resolve(false);
            });
        }

        function cleanup() {
            modalElement.removeEventListener("shown.bs.modal", onModalShown);
        }

        // Modal-Events setzen
        modalElement.addEventListener("shown.bs.modal", onModalShown);
        modalElement.addEventListener("hidden.bs.modal", cleanup);

        // Modal anzeigen
        modal.show();
    });
}

function customConfirmInit() {
    document.querySelectorAll("[data-confirm]").forEach((button) => {
        button.addEventListener("click", async function (event) {
            event.preventDefault(); // Verhindert das direkte Absenden des Formulars

            let confirmed = await customConfirm(this.dataset.confirm, {
                title: this.dataset.confirmTitle || "Bestätigung",
                okText: this.dataset.confirmOk || "OK",
                cancelText: this.dataset.confirmCancel || "Abbrechen",
                okClass: this.dataset.confirmOkClass || "btn-success", // Standard für OK
                cancelClass: this.dataset.confirmCancelClass || "btn-danger", // Standard für Abbrechen
            });

            if (confirmed) {
                // Wenn der Button ein Submit-Button ist, explizit diesen triggern
                if (this.type === "submit") {
                    this.closest("form").requestSubmit(this);
                } else {
                    this.closest("form").submit();
                }
            }
        });
    });
}
